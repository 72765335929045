import React, { lazy, Suspense } from 'react';

const ReactForOwner = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'ReactForOwnerLazy' */ './ReactForOwner'));

function ReactForOwnerLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading ReactForOwner ...</div>}>
                <ReactForOwner {...props} />
            </Suspense>
        </div>
    );
}

export default ReactForOwnerLazy;